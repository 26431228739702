<template>
  <div class="flex items-center">
    <IconAuthors class="w-5 pr-1" />
    <p>
      <router-link v-for="(author, index) of authors" :key="author.uuid" :to="authorUrl(author.slug)">
        <span class="underline hover:no-underline">{{ author.name }}</span
        ><span v-show="isLastElement(index)" class="pr-1">,</span>
      </router-link>
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AuthorModel } from '@/models/author.model';
import IconAuthors from '@/components/ui/icons/IconAuthors.vue';

export default defineComponent({
  components: { IconAuthors },
  props: {
    authors: {
      type: Array as PropType<AuthorModel[]>,
      default: (): Array<AuthorModel> => [],
    },
  },
  setup(props) {
    const authorUrl = (slug: string): string => {
      return `/authors/${slug}`;
    };

    const isLastElement = (index: number): boolean => {
      return index + 1 !== props.authors.length;
    };

    return { isLastElement, authorUrl };
  },
});
</script>
