import { de } from 'date-fns/locale';
import { StoryblokResult, StoryData } from 'storyblok-js-client';
import { ArticleModel } from '@/models/article.model';
import { format } from 'date-fns';

export function transformToStandardDate(date: string | null) {
  if (!date) {
    return '';
  }

  return format(new Date(date), 'dd MMMM yyyy', { locale: de });
}

export function formatDateFromArticleStory(story: StoryData<ArticleModel>): StoryData<ArticleModel> {
  return {
    ...story,
    published_at: transformToStandardDate(story.published_at),
    content: {
      ...story.content,
      date: transformToStandardDate(story.content.date),
    },
  };
}

export function parseStoryblokResultToStoryData(result: StoryblokResult): StoryData<ArticleModel>[] {
  return result.data.stories;
}

export function parseStoryblokResultToEntity<T>(result: StoryblokResult): T {
  if (result.data.story) {
    return result.data.story;
  }

  return result.data.stories[0];
}
