
import { defineComponent, PropType } from 'vue';
import { AuthorModel } from '@/models/author.model';
import IconAuthors from '@/components/ui/icons/IconAuthors.vue';

export default defineComponent({
  components: { IconAuthors },
  props: {
    authors: {
      type: Array as PropType<AuthorModel[]>,
      default: (): Array<AuthorModel> => [],
    },
  },
  setup(props) {
    const authorUrl = (slug: string): string => {
      return `/authors/${slug}`;
    };

    const isLastElement = (index: number): boolean => {
      return index + 1 !== props.authors.length;
    };

    return { isLastElement, authorUrl };
  },
});
